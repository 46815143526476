module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"manualInit":true,"modulePath":"/codebuild/output/src1498085275/src/gatsby-usa/src/cms/cms.js","enableIdentityWidget":false,"htmlFavicon":"src/images/favicon-512x512.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.utahsurgicalarts.com/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Utah Surgical Arts | Oral Surgery, Wisdom Teeth, Dental Implants","lang":"en","display":"standalone","short_name":"Utah Surgical Arts | Oral Surgery, Wisdom Teeth, Dental Implants","description":"Utah Surgical Arts has three convenient locations and offers the full scope of oral and maxillofacial surgery to patients of all ages.","start_url":"/","background_color":"#eafcfb","theme_color":"#0756a6","icon":"src/images/favicon-512x512.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"c82848f813e0c5cc4b5dd11a6024aa34"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TD6TBG6","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
